const colors = {
  // primary: '#cd1b54',
  // grey: '#a4a4a4',
  // darkGrey: '#272727',
  white: '#ffffff',
  // black: '#000000',
  // contentGrey: '#181818',
  // error: '#f44336',
  // success: "#00FF00",
  facebook: "#1877f2",
  twitter: "#1da1f2",
  // youtube: "#ff0000",
  // instagram: "#c32aa3",
  whatsapp: "#25d366",
  telegram: "#0088cc"
}

export default colors